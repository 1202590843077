@import '../../../styles/mixins.module';

.root {
  height: auto;

  &.large {
    width: 85px;

    @include tablet {
      width: 93px;
    }

    @include desktop {
      width: 116px;
    }
  }

  &.medium {
    width: 75px;
  }

  &.small {
    width: 57px;

    @include tablet {
      width: 69px;
    }

    @include desktop {
      width: 94px;
    }
  }
}
