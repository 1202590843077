@import '../../styles/typography';
@import '../../styles/mixins.module';
@import '../../styles/constants.module';

$image-dir: '/images/start-trial';

$bg-shape-image: url('#{$image-dir}/main-bg.svg');

.root {
  position: relative;

  padding-bottom: 50px;

  background-repeat: no-repeat;
  background-size: auto, 440px;

  @include only-mobile {
    overflow: hidden;
  }

  @include desktop {
    padding-bottom: 138px;

    &::after {
      content: '';

      position: absolute;

      width: 100%;
      height: 100%;

      background-repeat: no-repeat;
    }
  }

  &.home {
    .doodlePizza,
    .doodleRobot {
      display: block;
    }

    @include only-mobile {
      padding-top: 167px;

      background-image: url('#{$image-dir}/bg_start-trial_home_mobile.svg'), $bg-shape-image;
      background-position: calc(50% + 21px) 0, center 165px;

      .doodlePizza {
        top: 34px;
        right: calc(50% + 40px);
      }

      .doodleRobot {
        top: 445px;
        left: calc(50% + 85px);
      }
    }

    @include only-tablet {
      padding-top: 143px;

      background-image: url('#{$image-dir}/bg_start-trial_home_tablet.svg'), $bg-shape-image;
      background-position: calc(50% + 6px) -29px, center 152px;

      .doodlePizza {
        top: 64px;
        right: calc(50% + 172px);
      }

      .doodleRobot {
        bottom: 300px;
        left: calc(50% + 173px);
      }
    }

    @include desktop {
      overflow: hidden;

      padding-top: 186px;

      &::after {
        top: 0;

        background-image: url('#{$image-dir}/bg_start-trial_home_desktop.svg'), $bg-shape-image;
        background-position: calc(50% - 1px) -29px, center 58px;
      }

      .doodlePizza {
        top: 65px;
        right: calc(50% + 353px);
      }

      .doodleRobot {
        bottom: 129px;
        left: calc(50% + 509px);
      }
    }
  }

  &.bots {
    .doodleDog,
    .doodleEthereum {
      display: block;
    }

    @include only-mobile {
      padding-top: 115px;

      background-image: url('#{$image-dir}/bg_start-trial_bots_mobile.svg'), $bg-shape-image;
      background-position: calc(50% + 19px) -46px, center 113px;

      .doodleDog {
        right: calc(50% - 226px);
        bottom: 304px;
      }

      .doodleEthereum {
        top: 10px;
        right: calc(50% + 70px);
      }
    }

    @include only-tablet {
      padding-top: 121px;

      background-image: url('#{$image-dir}/bg_start-trial_bots_tablet.svg'), $bg-shape-image;
      background-position: calc(50% + 3px) 0, center 141px;

      .doodleDog {
        right: calc(50% - 338px);
        bottom: 156px;
      }

      .doodleEthereum {
        top: 110px;
        right: calc(50% + 210px);
      }
    }

    @include desktop {
      padding-top: 227px;

      &::after {
        top: -8px;

        background-image: url('#{$image-dir}/bg_start-trial_bots_desktop.svg'), $bg-shape-image;
        background-position: calc(50% + 7px) -11px, center 108px;
      }

      .doodleDog {
        right: calc(50% - 515px);
        bottom: 56px;
      }

      .doodleEthereum {
        top: 140px;
        right: calc(50% + 450px);
      }
    }
  }

  &.trading {
    @include only-mobile {
      padding-top: 114px;

      background-image: url('#{$image-dir}/bg_start-trial_trading_mobile.svg'), $bg-shape-image;
      background-position: calc(50% - 30px) 0, center 112px;

      .doodleBitcoin {
        top: 22px;
        right: calc(50% + 84px);

        display: block;
      }

      .doodleRocket {
        top: 404px;
        left: calc(50% + 47px);

        display: block;
      }
    }

    @include only-tablet {
      padding-top: 161px;

      background-image: url('#{$image-dir}/bg_start-trial_trading_tablet.svg'), $bg-shape-image;
      background-position: calc(50% + 6px) 0, center 165px;

      .doodleBitcoin {
        top: 82px;
        right: calc(50% + 170px);

        display: block;
      }

      .doodleRocket {
        top: 446px;
        left: calc(50% + 173px);

        display: block;
      }
    }

    @include desktop {
      padding-top: 228px;

      &::after {
        top: -12px;

        background-image: url('#{$image-dir}/bg_start-trial_trading_desktop.svg'), $bg-shape-image;
        background-position: calc(50% + 20px) 0, center 112px;
      }

      .doodleBitcoin {
        top: 29px;
        right: calc(50% + 427px);

        display: block;
      }

      .doodleRocket {
        top: 516px;
        left: calc(50% + 432px);

        display: block;
      }
    }
  }
}

.startTrial {
  position: relative;
  z-index: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.steps {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;

  @include desktop {
    flex-direction: row;
    justify-content: space-between;

    margin-left: 20px;

    &::after {
      content: '';

      position: absolute;
      bottom: -22px;
      left: 54px;

      width: 1006px;
      height: 211px;

      background-image: url('#{$image-dir}/steps-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
    }
  }
}

.stepWrapper {
  position: relative;
}

.step {
  @include text-h3;

  text-align: center;

  &.startTrading {
    padding: 11px 0 12px;

    @include tablet {
      padding: 7px 0 12px;
    }
  }

  &.signUp {
    padding-bottom: 18px;
  }

  &.connect {
    padding: 10px 0 11px;

    @include tablet {
      margin-left: 5px;
    }
  }

  @include desktop {
    &.signUp,
    &.connect,
    &.startTrading {
      padding: 0;
    }

    &.connect {
      margin-left: 64px;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 100%;
  max-width: 1015px;

  @include desktop {
    > :not(:last-child) {
      margin-bottom: 62px;
    }
  }
}

.title {
  position: relative;

  max-width: 260px;
  margin-bottom: 27px;

  text-align: center;

  @include text-h2;

  @include desktop {
    max-width: 600px;
    margin-bottom: 81px;
  }
}

.description {
  order: 1;

  max-width: 343px;

  text-align: center;

  @include text-body-m;

  @include tablet {
    max-width: 440px;
  }

  @include desktop {
    order: initial;

    max-width: 855px;
  }
}

.button {
  padding: 27px 0 30px;

  @include desktop {
    padding: 0;
  }
}

.doodleDog,
.doodleEthereum,
.doodlePizza,
.doodleRobot,
.doodleBitcoin,
.doodleRocket {
  position: absolute;
  z-index: -1;

  display: none;
}

.doodleDog {
  transform: rotate(28.75deg);
}

.doodleEthereum {
  transform: rotate(-30deg);
}

.doodlePizza {
  transform: rotate(54deg) scale(-1, 1);
}

.doodleRobot {
  transform: matrix(0.95, 0.31, -0.31, 0.95, 0, 0);
}

.doodleBitcoin {
  transform: rotate(-23.72deg);
}

:global(.fr),
:global(.vi) {
  @include only-mobile {
    .buttonContent {
      font-size: 17px;
    }
  }
}

:global(.tr) {
  @include only-mobile {
    .buttonContent {
      font-size: 16px;
    }
  }
}
