@import '../../styles/mixins.module';
@import '../../styles/constants.module';

.root {
  padding: 0 16px;

  @include tablet {
    padding: 0 50px;
  }

  @include desktop {
    width: 100%;
    max-width: $desktop-content-max-width;
    margin: 0 auto;
    padding: 0;
  }

  &.centered {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &.fullHeight {
    height: 100%;
  }
}
