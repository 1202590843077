@import '../../styles/constants.module';

.content {
  padding: 0;

  border-radius: 8px;
  background-color: $white;
  box-shadow: 0 3px 23px $grey-6;
}

.popper {
  z-index: 2;

  padding-top: 18px;
}

.arrowIcon {
  width: 20px;
}

.endIcon {
  margin-left: 6px;
  margin-right: -2px;
}
