// stylelint-disable-next-line value-keyword-case
$app-font-family: Inter, -apple-system, BlinkMacSystemFont, 'Segoe UI', Oxygen, Ubuntu, Cantarell,
  'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
$desktop-content-max-width: 1240px;
$header-height-mobile: 72px;
$header-height-tablet: 82px;
$header-height-desktop: 80px;

:export {
  HEADER_HEIGHT_MOBILE: $header-height-mobile;
  HEADER_HEIGHT_TABLET: $header-height-tablet;
  HEADER_HEIGHT_DESKTOP: $header-height-desktop;
}

// colors
$white: #fff;
$black: #000;

// blue
$blue-1: #274af2;
$blue-2: #523afd;
$blue-3: #0c2bc0;
$blue-4: #e3eefc;
$blue-5: #e4e1ff;
$blue-6: #ccfafe;
$blue-7: #0d35c5;

// grey
$grey-1: #f7f7f7;
$grey-2: #c4c4c4;
$grey-3: #464646;
$grey-4: #838383;
$grey-5: #dcdcdc;
$grey-6: rgba(146, 162, 187, 0.2);
$grey-7: rgba(70, 70, 70, 0.3);
$grey-8: #808080;
$grey-9: #bfbfbf;
$grey-10: rgba(0, 0, 0, 0.07);
$grey-11: #171c21;
$grey-12: #dfe3e9;
$grey-13: #f2f2f2;
$grey-14: rgba(0, 0, 0, 0.5);
$grey-15: #687385;
$grey-16: rgba(0, 0, 0, 0.77);
$grey-17: #15171a;

// green
$green-1: #07c37a;
$green-2: #029747;
$green-3: #05945c;
$green-4: #e1fef3;
$green-5: #28c59f;

// yellow
$yellow-1: #f7c23b;
$yellow-2: #ffd55c;
$yellow-3: #ffefc2;

// red
$red-1: #f23327;
$red-2: #ffe0ea;
$red-3: #f03f47;
$red-4: #b42b31;

// orange
$orange-1: #ec5e32;
$orange-2: #df640c;
$orange-3: #ffcca6;
$orange-4: #ffeada;
$orange-5: #ffebe4;

:export {
  APP_FONT_FAMILY: $app-font-family;
  BLACK: $black;
  BLUE_1: $blue-1;
  WHITE: $white;
}

$small-doodles-dir: '/images/small-doodles';
