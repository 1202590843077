@import '../../../styles/mixins.module';

.root {
  height: auto;

  &.large {
    width: 128px;

    @include tablet {
      width: 120px;
    }

    @include desktop {
      width: 151px;
    }
  }

  &.medium {
    width: 98px;
    height: 20px;
  }

  &.small {
    width: 65px;

    @include tablet {
      width: 84px;
    }

    @include desktop {
      width: 116px;
    }
  }
}
