@import '../../../styles/constants.module';
@import '../../../styles/mixins.module';

.root {
  display: flex;
  flex-direction: column;

  padding: 15px 4px;

  &:not(.withoutGaps) {
    > :not(:last-child) {
      margin-bottom: 11px;
    }
  }

  &.large {
    @include desktop {
      padding: 20px 26px;

      .listItem {
        padding: 12px 10px;
      }
    }
  }
}

.listItem {
  display: block;

  padding: 6.5px 26px;

  cursor: pointer;

  color: $black;

  font-size: 18px;
  font-weight: 400;
  line-height: 22px;

  @include keyboard-focus-only;

  &:hover {
    border-radius: 5px;
    background-color: $grey-1;
  }

  // font-weight: bold of selected modifier increases the dropdown width so
  // we need this pseudo-element to prevent it (https://stackoverflow.com/a/20249560)

  &::before {
    content: attr(title);

    display: block;
    visibility: hidden;
    overflow: hidden;

    height: 0;

    font-weight: bold;
  }

  &.selected {
    font-weight: bold;
  }
}
