@import '../../styles/mixins.module';

.root {
  overflow-x: auto;
  overflow-y: visible;

  padding-bottom: 18px;

  @include desktop {
    @include scrollbar;
    padding-bottom: 39px;

    &::-webkit-scrollbar {
      &-button {
        width: 100px;

        background: transparent;
      }
    }
  }
}

.reviewsList {
  display: flex;

  width: fit-content;
  padding: 0 16px;

  @include tablet {
    padding: 0 50px;
  }

  @include desktop {
    padding: 0 100px;
  }
}

.cardWrapper {
  &:not(:last-child) {
    margin-right: 18px;

    @include desktop {
      margin-right: 60px;
    }
  }
}
