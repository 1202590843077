@import './constants.module';

// We use 0.98px to avoid issues on high-dpi devices, zoom and screen splitting
// This workaround used in bootstrap https://docs.w3cub.com/bootstrap~5/layout/breakpoints/index
$mobile-max-width: 767.98px;
$tablet-min-width: 768px;
$tablet-max-width: 1279.98px;
$desktop-min-width: 1280px;

:export {
  DESKTOP_MIN_WIDTH: $desktop-min-width;
  MOBILE_MAX_WIDTH: $mobile-max-width;
  TABLET_MAX_WIDTH: $tablet-max-width;
  TABLET_MIN_WIDTH: $tablet-min-width;
}

@mixin tablet {
  @media only screen and (min-width: $tablet-min-width) {
    @content;
  }
}

@mixin desktop {
  @media only screen and (min-width: $desktop-min-width) {
    @content;
  }
}

@mixin only-mobile {
  @media only screen and (max-width: $mobile-max-width) {
    @content;
  }
}

@mixin only-tablet {
  @media only screen and (min-width: $tablet-min-width) and (max-width: $tablet-max-width) {
    @content;
  }
}

@mixin text-container {
  max-width: 343px;
  margin: 0 auto;

  @include tablet {
    max-width: 440px;
  }

  @include desktop {
    max-width: 860px;
  }
}

@mixin table-container {
  overflow: scroll;

  @include desktop {
    overflow: auto;
  }

  table {
    width: 860px;
    margin: 0 16px;

    @include tablet {
      margin: 0 50px;
    }

    @include desktop {
      margin: 0 auto;
    }
  }
}

@mixin scrollbar {
  scrollbar-color: $blue-1 $grey-2; // firefox
  scrollbar-width: thin; // firefox

  &::-webkit-scrollbar {
    width: 4px;
    height: 4px;

    border-radius: 1px;
    background-color: transparent;

    &-thumb {
      border-radius: 2px;
      background-color: $blue-1;
    }

    &-track {
      background-color: $grey-2;
      background-clip: padding-box;

      &:horizontal {
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
      }

      &:vertical {
        border-left: 1px solid transparent;
        border-right: 1px solid transparent;
      }
    }
  }
}

@mixin keyboard-focus-only {
  // this mixin is needed to add focus without showing it on click
  // https://stackoverflow.com/a/45191208
  &:focus {
    border-radius: 5px;
    outline: 1px solid $blue-2;
  }

  &:focus:not(:focus-visible) {
    outline: none;
  }
}

@mixin attention() {
  &:active {
    @content;
  }

  @include desktop {
    &:hover,
    &:focus-visible {
      @content;
    }
  }
}

@mixin text-with-ellipsis($rows-mobile, $rows-tablet: null, $rows-desktop: null) {
  // stylelint-disable-next-line value-no-vendor-prefix
  display: -webkit-box; // required for multiline ellipsis
  overflow: hidden;

  -webkit-box-orient: vertical;
  -webkit-line-clamp: $rows-mobile;

  @if $rows-tablet {
    @include tablet {
      -webkit-line-clamp: $rows-tablet;
    }
  }

  @if $rows-desktop {
    @include desktop {
      -webkit-line-clamp: $rows-desktop;
    }
  }
}

@mixin mui-default-transition($property, $duration: 300ms) {
  transition: $property $duration cubic-bezier(0.4, 0, 0.2, 1) 0ms;
}

@mixin small-doodle-bg($postfix: '01', $positionX: 0, $positionY: 0) {
  background-image: url('#{$small-doodles-dir}/small-doodle-#{$postfix}.svg');
  background-repeat: no-repeat;
  background-position: $positionX $positionY;
  background-size: auto;
}
