@import '../../../styles/mixins.module';
@import '../../../styles/typography';
@import '../../../styles/constants.module';

$layout-horizontal-margin: 16px;
$tablet-link-horizontal-margin: 35px;
$nav-button-height: 65px;

.backdrop {
  background-color: $grey-7;
}

.drawerPaper {
  width: 446px;

  box-shadow: 0 3px 23px $grey-6;
}

.menuHeader {
  display: flex;

  padding: 0 $layout-horizontal-margin;

  @include tablet {
    padding: 4px 49px 0 33px;
  }
}

.menuLogo {
  display: flex;
  align-items: center;

  height: 100%;
}

.languageExpandButton {
  display: flex;
  align-items: flex-start;

  height: 58px;
  margin-left: 26px;
  margin-top: 14px;
  padding: 10px 5px 0 15px;

  color: $black;

  border: 0;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: transparent;

  font-family: inherit;

  @include text-button-secondary;

  &.expanded {
    background-color: $grey-1;
  }

  @include tablet {
    height: 53px;
    margin-left: 0;
    margin-top: 16px;
    padding: 11px 4px 0 17px;
  }
}

.languageMenuItems {
  display: inline-flex;
  flex-direction: column;

  width: max-content;
}

.arrowIcon {
  width: 22px;
  margin-left: 3px;

  @include mui-default-transition(transform, 150ms);

  &.expanded {
    transform: rotate(180deg);
  }
}

.closeButtonWrapper {
  display: flex;
  align-items: center;

  margin-left: auto;
}

.closeButton {
  display: flex;

  padding: 0;

  border: 0;
  background-color: transparent;

  @include tablet {
    width: 37px;
    height: 37px;
  }
}

.languageButtonsRoot {
  background-color: $grey-1;
}

.languageButtonsWrapper {
  display: flex;
  overflow-x: scroll;

  padding: 8px $layout-horizontal-margin;

  @include tablet {
    padding: 10px 16px;
  }
}

.languageButtonItem {
  display: flex;
  align-items: center;
}

.languageButton {
  font-size: 18px;
  line-height: 22px;

  @include tablet {
    @include text-button-secondary;
  }

}

.languageShort {
  color: $grey-2;
}

.divider {
  width: 8px;
  margin-left: 16.5px;
  margin-right: 16.5px;

  background-color: $grey-2;
}

.menuNavigation {
  margin-top: 17px;

  &.withLargeIndent {
    margin-top: 36px;
  }

  @include tablet {
    margin-top: 12px;
  }
}

.accordionRoot {
  background-color: unset;
  box-shadow: none;

  &::before {
    display: none;
  }
}

.accordionSummary {
  min-height: $nav-button-height;
  margin: 0 $layout-horizontal-margin;
  padding: 0;

  border-top: 1px solid $grey-2;

  @include text-h4;

  &.withoutBorder {
    border-color: transparent;
  }

  @include tablet {
    margin: 0 50px;
  }
}

.navButton {
  position: relative;

  display: flex;
  align-items: center;

  min-height: $nav-button-height;
  padding: 0 $layout-horizontal-margin;

  @include text-h4;

  // this pseudo-element is needed to add border-top smaller than .navButton width
  &::before {
    content: '';

    position: absolute;
    top: 0;
    right: $layout-horizontal-margin;
    left: $layout-horizontal-margin;

    border-top: 1px solid $grey-2;
  }

  &.highlighted {
    background-color: $grey-1;
  }

  @include tablet {
    margin: 0 ($tablet-link-horizontal-margin - 1px);
  }
}

.accordionSummaryContent {
  margin: 0 20px 0 0;

  &.highlighted {
    color: $blue-1;
  }
}

.expandIconWrapper {
  position: relative;
  top: 20px;
  right: 3px;

  align-self: flex-start;

  @include tablet {
    right: 6px;
  }
}

.expandIcon {
  width: 23px;
  height: 23px;
}

.accordionDetails {
  padding: 0 0 10px;

  @include tablet {
    padding: 0 $tablet-link-horizontal-margin 10px;
  }
}

.navLinks {
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 7px;
  }
}

.navLink {
  display: block;

  padding: 9px $layout-horizontal-margin;

  @include text-button-secondary;

  &.highlighted {
    background-color: $grey-1;

    font-weight: bold;
  }
}
