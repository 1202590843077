@import '../../styles/typography';

$horizontal-margin: 13px;

.root {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;

  max-width: 230px;

  letter-spacing: -0.009em;

  font-size: 20px;
  line-height: 32px;

  > :not(:last-child) {
    margin-bottom: 6px;
  }

  @include tablet {
    flex-wrap: nowrap;

    max-width: none;

    > :not(:last-child) {
      margin-right: $horizontal-margin;
      margin-bottom: 0;
    }
  }
}

.logo {
  display: flex;

  margin-left: $horizontal-margin;
}

.stars,
.ratingBlock {
  display: flex;
  align-items: center;
}

:global(.tr) {
  @include only-mobile {
    .ratingBlock {
      font-size: 16px;
    }
  }
}
