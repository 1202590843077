@import '../../styles/constants.module';
@import '../../styles/mixins.module';

.root {
  width: max-content;

  @include keyboard-focus-only;

  &.blue {
    color: $blue-1;

    &:hover {
      color: $black;
    }

    &:active {
      color: $blue-3;
    }
  }

  &.black {
    color: $black;

    &:hover {
      color: $blue-1;
    }

    &:active {
      color: $blue-3;
    }
  }
}
