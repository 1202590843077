@import '../../styles/constants.module';
@import '../../styles/typography';
@import '../../styles/mixins.module';

.root {
  padding: 50px 0 0;

  @include desktop {
    padding: 100px 0 0;
  }
}

.wrapper {
  @include tablet {
    display: flex;
    justify-content: space-between;
  }

  @include desktop {
    display: flex;
    justify-content: flex-start;
  }
}

.colorBlue {
  color: $blue-1;
}

.generalInfo {
  @include tablet {
    max-width: 505px;
    margin-right: 65px;
  }

  @include desktop {
    margin-right: 167px;
  }
}

.title {
  margin-bottom: 8px;

  font-size: 50px;
  font-weight: bold;
  line-height: 55px;

  @include tablet {
    margin-bottom: 10px;
  }

  @include desktop {
    margin-bottom: 16px;

    font-size: 68px;
    line-height: 75px;
  }
}

.description {
  padding-top: 16px;

  @include text-body;

  @include desktop {
    padding-top: 24px;
  }
}

.advantagesList {
  display: flex;
  flex-direction: column;

  margin-top: 25px;

  @include tablet {
    min-width: 277px;
    max-width: 495px;
    margin-top: 9px;
  }

  @include desktop {
    margin-top: 56px;
  }
}

.item {
  display: flex;

  &:not(:last-child) {
    margin-bottom: 20px;

    @include desktop {
      margin-bottom: 40px;
    }
  }
}

.checkIcon {
  margin-right: 16px;

  color: $blue-1;

  @include desktop {
    width: 32px !important;
    height: 32px !important;
  }
}

.itemTitle {
  margin-top: 1px;

  @include text-h4;
}

.itemDescription {
  margin-top: 8px;

  font-size: 14px;
  line-height: 21px;

  @include desktop {
    font-size: 16px;
    line-height: 24px;
  }

  @include desktop {
    margin-top: 16px;
  }
}
