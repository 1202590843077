@import './mixins.module';

@mixin text-h1($lineHeight: 44px, $lineHeight-t: 48px, $lineHeight-d: 70px) {
  font-size: 40px;
  font-weight: bold;
  line-height: $lineHeight;

  @include tablet {
    font-size: 44px;
    line-height: $lineHeight-t;
  }

  @include desktop {
    font-size: 64px;
    line-height: $lineHeight-d;
  }
}

@mixin text-h2 {
  font-size: 30px;
  font-weight: bold;
  line-height: 36px;

  @include tablet {
    font-size: 30px;
    line-height: 36px;
  }

  @include desktop {
    font-size: 50px;
    line-height: 60px;
  }
}

@mixin text-h3 {
  font-size: 26px;
  font-weight: bold;
  line-height: 31px;

  @include tablet {
    font-size: 28px;
    line-height: 34px;
  }

  @include desktop {
    font-size: 32px;
    line-height: 39px;
  }
}

@mixin text-h4 {
  font-size: 20px;
  font-weight: bold;
  line-height: 24px;

  @include desktop {
    font-size: 26px;
    line-height: 34px;
  }
}

@mixin text-h5 {
  font-size: 16px;
  font-weight: bold;
  line-height: 19px;

  @include desktop {
    font-size: 20px;
    line-height: 27px;
  }
}

@mixin text-h6 {
  font-size: 14px;
  font-weight: bold;
  line-height: 18px;

  @include desktop {
    font-size: 18px;
    line-height: 23px;
  }
}

@mixin text-body-l {
  font-size: 18px;
  font-weight: bold;
  line-height: 24px;

  @include tablet {
    font-size: 20px;
    line-height: 24px;
  }

  @include desktop {
    font-size: 24px;
    line-height: 34px;
  }
}

@mixin text-body-m {
  font-size: 18px;
  line-height: 24px;

  @include desktop {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin text-body-s {
  font-size: 16px;
  line-height: 24px;

  @include desktop {
    font-size: 20px;
    line-height: 30px;
  }
}

@mixin text-body {
  font-size: 14px;
  line-height: 21px;

  @include desktop {
    font-size: 16px;
    line-height: 24px;
  }
}

@mixin text-button-primary {
  font-size: 18px;
  line-height: 24px;

  @include desktop {
    font-size: 24px;
    line-height: 32px;
  }
}

@mixin text-button-secondary {
  font-size: 20px !important;
  line-height: 24px !important;

  @include tablet {
    font-size: 18px !important;
    line-height: 22px !important;
  }
}

@mixin text-caption {
  font-size: 10px;
  line-height: 13px;

  @include desktop {
    font-size: 12px;
    line-height: 16px;
  }
}
