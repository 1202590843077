/*stylelint-disable selector-class-pattern*/
@import './mixins.module';

.mobile {
  @include tablet {
    display: none !important;
  }
}

.tablet {
  @include only-mobile {
    display: none !important;
  }

  @include desktop {
    display: none !important;
  }
}

.desktop {
  @include only-mobile {
    display: none !important;
  }

  @include only-tablet {
    display: none !important;
  }
}

.mobile-desktop {
  @include only-tablet {
    display: none !important;
  }
}

.mobile-tablet {
  @include desktop {
    display: none !important;
  }
}

.tablet-desktop {
  @include only-mobile {
    display: none !important;
  }
}
