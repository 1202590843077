@import '../../styles/mixins.module';
@import '../../styles/typography';
@import '../../styles/constants.module';

.root {
  position: sticky;
  top: 0;
  z-index: 1501; // we use MUI Tooltip that has z-index 1500 and we want it to be displayed under header

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100%;

  max-width: 100vw;
  height: $header-height-mobile;

  padding: 0 14px 0 16px;

  contain: layout;

  background: rgba($white, 0.8);

  @include tablet {
    height: $header-height-tablet;
    padding: 0 47px 0 49px;
  }

  @include desktop {
    height: $header-height-desktop;
    padding: 0;
  }

  &.mobileAndTablet {
    display: flex;

    @include desktop {
      display: none;
    }
  }

  &.desktop {
    display: none;

    @include desktop {
      display: flex;
    }
  }
}

.wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.searchButton {
  margin-left: auto;
  margin-right: 10px;

  cursor: pointer;
}

.menuButton,
.searchButton {
  width: 40px;
  height: 40px;
  padding: 0;

  border: 0;
  background-color: transparent;
}

.side {
  display: flex;
  align-items: center;
}

.rightSide {
  > :not(:last-child) {
    margin-right: 9px;
  }
}

.navigation {
  display: flex;
  align-items: center;

  margin-left: 64px;
}

.languageSelect {
  width: 89px;

  &Blog {
    margin-right: 10px;
  }
}

.modalContent {
  max-width: 311px;

  @include only-tablet {
    min-width: 440px;
  }

  @include desktop {
    max-width: 505px;
  }
}

.longLanguage {
  font-weight: normal;
}

.shortLanguage {
  &:not(.active) {
    color: $grey-2;
  }
}
