@import "../../styles/typography";
@import "../../styles/constants.module";

$image-dir: "/images/main-promo";
$bg-shape-image: url("#{$image-dir}/bg_shape.svg");
$bg-bots-page-image: url("#{$image-dir}/bg_bot_desktop.svg");
$bg-shape-size-mobile: 440px 270px;
$bg-shape-size-desktop: 820px 500px;

:export {
  DOODLES_IMAGE_DIR: $small-doodles-dir;
  IMAGE_DIR: $image-dir;
}

.root {
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  padding: 67px 0 50px;

  background-repeat: no-repeat;

  background-position: center 0, calc(50% - 0px) 48px, center 37px;
  background-size: auto, auto, $bg-shape-size-mobile;

  @include tablet {
    padding: 57px 0 92px;
  }

  @include desktop {
    overflow: hidden;

    padding: 140px 0;

    background-position: center 29px, calc(50% - 0px) 48px, center -15px;
    background-size: auto, auto, $bg-shape-size-desktop;
  }

  &.home {
    padding-bottom: 0;

    background-image: url("#{$small-doodles-dir}/small-doodle-12.svg"),
      $bg-shape-image;
    background-position: calc(50% + 153px) top, center top;
    background-size: auto, $bg-shape-size-mobile;

    .doodleEyes {
      top: 0;
      right: calc(50% + 136px);

      display: block;
    }

    @include only-tablet {
      background-image: url("#{$image-dir}/bg_home_tablet.svg"), $bg-shape-image;
      background-position: calc(50% + 7px) 115px, center top;

      .doodleBitcoin {
        bottom: 78px;
        left: calc(50% + 203px);

        display: block;
      }

      .doodleEyes {
        top: 286px;
        right: calc(50% + 229px);
      }
    }

    @include desktop {
      background-image: url("#{$image-dir}/bg_home_desktop.svg"),
        $bg-shape-image;
      background-position: calc(50% + 14px) 85px, center 100px;
      background-size: auto, $bg-shape-size-desktop;

      .doodleBitcoin {
        top: 264px;
        left: calc(50% + 505px);

        display: block;
      }

      .doodleEyes {
        top: 277px;
        right: calc(50% + 569px);

        display: block;
      }
    }
  }

}

.title {
  z-index: 1;

  margin-bottom: 20px;

  text-align: center;

  font-style: normal;

  @include text-h1;

  @include tablet {
    max-width: 668px;
  }

  @include desktop {
    max-width: 1080px;
    margin-bottom: 40px;
  }

}

.lineThrough {
  position: relative;

  text-decoration: none;

  &::before {
    content: "";

    position: absolute;
    top: 24px;

    width: 100%;
    height: 4px;

    background: $black;

    @include tablet {
      top: 28px;
    }

    @include desktop {
      top: 41px;

      height: 8px;
    }
  }
}

.text {
  box-sizing: content-box;
  max-width: 668px;
  margin-bottom: 20px;

  text-align: center;

  color: $grey-3;

  font-weight: normal;
  font-style: normal;

  @include text-body-m;

  @include tablet {
    margin-bottom: 40px;
  }

  @include desktop {
    max-width: 820px;
  }
}

.remark {
  margin-top: 8px;

  text-align: center;

  font-weight: normal;
  font-style: normal;

  @include text-body;

  @include desktop {
    margin-top: 16px;
  }
}

.exchangesContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  margin-top: 30px;

  @include tablet {
    margin-top: 60px;
  }

  @include desktop {
    flex-direction: row;

    margin-top: 70px;
  }
}

.exchangesText {
  text-align: center;
  color: $grey-3;

  @include text-h5;

  @include only-mobile {
    max-width: 280px;
  }
}

.moreExchanges {
  color: $grey-9;

  font-weight: normal;

  @include text-body-m;

  &:hover,
  &:focus {
    color: $blue-1;
  }

  @include only-mobile {
    margin-top: 20px;
  }

  @include tablet {
    margin-left: 11px;
    padding-top: 4px;
  }

  @include desktop {
    width: max-content;
    margin-left: 12px;
    padding-top: 0;

    @include text-h5;
  }
}

.exchangesWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 17px;

  @include tablet {
    flex-direction: row;

    margin-top: 16px;
  }

  @include desktop {
    margin-top: 0;
  }
}

.exchangesList {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  @include tablet {
    align-items: center;
  }

  @include desktop {
    flex-wrap: nowrap;
    align-items: normal;

    margin-left: 60px;
  }
}

.exchangeLogoWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  width: 74px;
  height: 24px;

  margin: 0 4px;

  @include tablet {
    width: 91px;
    height: 35px;
    margin: 0 11px;
  }

  @include desktop {
    width: 130px;
    height: 50px;
    margin: 0 12px;

    &.bybit {
      width: 130px;
      height: 50px;
    }
  }

  &.binance,
  &.okx,
  &.kucoin {
    opacity: 0.25;
  }

  &.exchangeLink:hover,
  &.exchangeLink:focus {
    &.binance,
    &.okx,
    &.kucoin {
      opacity: 1;
    }
  }
}

.mobileBreak {
  display: none;

  @include only-mobile {
    display: initial;
  }
}

.tabletBreak {
  display: none;

  @include tablet {
    display: initial;
  }
}

.doodleBear,
.doodleBull,
.doodleDog,
.doodleEthereum,
.doodleBitcoin,
.doodleRocket,
.doodleIncognito {
  position: absolute;
  z-index: -1;

  display: none;
}

.doodleEyes {
  position: absolute;

  display: none;
}

.doodleDog {
  top: 41px;
  left: calc(50% + 518px);

  transform: rotate(15deg);
}

.doodleBitcoin {
  transform: rotate(31.4deg);
}

.doodleEthereum {
  transform: rotate(-30deg);
}

.word {
  display: inline-block;
}

:global(.id) {
  .demo {
    @include only-mobile {
      .title {
        font-size: 38px;
      }
    }
  }
}

:global(.tr) {
  .home {
    @include only-mobile {
      .title {
        font-size: 32px;
      }
    }

    @include desktop {
      .title {
        max-width: 900px;
      }
    }
  }
}

:global(.ru) {
  @include only-mobile {
    .buttonText {
      font-size: 13px;
    }

    .title {
      font-size: 34px;
    }
  }

  .security {
    @include only-mobile {
      .buttonText {
        font-size: inherit;
      }
    }
  }

  .futuresBot {
    @include desktop {
      .text {
        max-width: 790px;
      }
    }
  }
}

:global(.fr) {
  @include only-mobile {
    .buttonText {
      font-size: 13px;
    }
  }

  .security {
    @include only-mobile {
      .buttonText {
        font-size: 17px;
      }
    }
  }
}

:global(.tr) {
  @include only-mobile {
    .buttonText {
      font-size: 13px;
    }
  }

  @include desktop {
    .exchangesText {
      max-width: 250px;
    }
  }

  .security {
    @include only-mobile {
      .buttonText {
        font-size: 16px;
      }
    }
  }
}

:global(.vi) {
  @include only-mobile {
    .buttonText {
      font-size: 14px;
    }
  }

  .security {
    @include only-mobile {
      .buttonText {
        font-size: inherit;
      }
    }
  }

  .home {
    @include desktop {
      .title {
        max-width: 1000px;
      }
    }
  }
}

:global(.id) {
  @include only-mobile {
    .buttonText {
      font-size: 17px;
    }
  }

  .security {
    @include only-mobile {
      .buttonText {
        font-size: 17px;
      }
    }
  }
}
