@import '../../styles/constants.module';
@import '../../styles/typography';

$light-blue: #a8bbfd;
$blue-hover: #1c38d0;
$blue-active: #1328ae;

.root {
  outline: none;

  &.uppercase {
    text-transform: uppercase;
  }
}

.contained {
  &:disabled {
    cursor: not-allowed;
  }
}

.outlined {
  border: 1px solid;
  background: transparent;

  &:disabled {
    cursor: not-allowed;

    color: $grey-2;
    border-color: $grey-2;
    background: $white;
  }
}

.containedSizeXSmall {
  padding: 8px 32px !important;

  border-radius: 30px !important;

  font-size: 20px !important;
  line-height: 24px !important;

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    padding: 6px 30px !important;;
  }
}

.containedSizeSmall {
  padding: 11px 32px !important ;

  border-radius: 30px !important;

  font-size: 18px !important;
  line-height: 22px !important;

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    padding: 9px 30px !important;;
  }

  @include desktop {
    padding: 11px 37px !important;

    // stylelint-disable-next-line selector-class-pattern
    &:global(.Mui-focusVisible) {
      padding: 9px 35px !important;
    }
  }
}

.containedSizeMedium {
  padding: 16px 30px !important;

  border-radius: 33px !important;

  font-size: 20px !important;;
  line-height: 24px !important;;

  @include desktop {
    padding: 17px 32px !important;;
  }

  &.uppercase {
    @include text-button-primary;
  }

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    padding: 14px 30px !important;

    @include desktop {
      padding: 15px 30px !important;
    }
  }
}

.outlinedSizeSmall {
  padding: 7px 40px !important;

  border-radius: 30px !important;

  @include text-button-secondary;

  @include only-tablet {
    font-size: 20px !important;
    line-height: 24px !important;
  }

  @include desktop {
    padding: 10px 37px !important;;
  }

  &.fullWidth .content {
    width: 100%;
  }

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    padding: 1px;

    .content {
      padding: 5px 38px !important;

      border: 1px solid !important;
      border-radius: 30px !important;

      @include desktop {
        padding: 8px 35px !important;
      }
    }
  }
}

.outlinedSizeMedium {
  padding: 16px 32px !important;

  border-radius: 60px !important;

  font-size: 18px !important;
  line-height: 24px !important;

  @include tablet {
    padding: 18.5px 23px !important;

    font-size: 14px !important;
    line-height: 19px !important;
  }

  @include desktop {
    padding: 20px 32px !important;

    font-size: 18px !important;
    line-height: 24px !important;
  }

  &.fullWidth .content {
    width: 100%;
  }

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    padding: 1px;

    .content {
      padding: 15px 30px !important;;

      border: 1px solid !important;;
      border-radius: 60px !important;;

      @include tablet {
        padding: 17.5px 20px !important;;
      }

      @include desktop {
        padding: 19px 30px !important;;
      }
    }
  }
}

.containedPrimary {
  color: $white;
  border-color: $blue-1;
  background: $blue-1;

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    border: 2px solid $blue-1;
    background: $blue-1;
    box-shadow: inset 0 0 0 1px $light-blue;
  }

  &:hover {
    border-color: $blue-hover;
    background: $blue-hover;
    box-shadow: none;
  }

  &:active {
    border-color: $blue-active;
    background: $blue-active;
    box-shadow: none;
  }

  &:disabled {
    color: $grey-2;
    border-color: $grey-2;
    background: $white;
  }
}

.containedSecondary {
  color: $blue-1;
  border-color: $white;
  background: $white;

  &:focus {
    border-color: $white;
    background: $white;
    box-shadow: inset 0 0 0 1px $light-blue;
  }

  &:hover,
  &:focus:hover {
    border-color: rgba(255, 255, 255, 0.7);
    background: rgba(255, 255, 255, 0.7);
    box-shadow: none;
  }

  &:active,
  &:focus:active {
    border-color: rgba(255, 255, 255, 0.5);
    background: rgba(255, 255, 255, 0.5);
    box-shadow: none;
  }

  &:disabled {
    cursor: not-allowed;

    color: $grey-2;
    border-color: $grey-2;
    background: $white;
  }

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) {
    border: 2px solid $white;
  }
}

.outlinedPrimary {
  color: $blue-1;

  &:hover {
    background: rgba($blue-1, 0.1);
  }

  &:active {
    background: rgba($blue-1, 0.25);
  }

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) .content {
    border-color: $blue-1;
  }
}

.outlinedSecondary {
  color: $white;
  border-color: $white;

  &:hover {
    border-color: $white;
    background: rgba($white, 0.1);
  }

  &:active {
    border-color: $white;
    background: rgba($white, 0.25);
  }

  // stylelint-disable-next-line selector-class-pattern
  &:global(.Mui-focusVisible) .content {
    box-shadow: inset 0 0 0 1px $white;
  }
}

.content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
