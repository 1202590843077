@import '../../../styles/constants.module';
@import '../../../styles/typography';

.card {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  width: 299px;
  height: 426px;
  padding: 23px;

  text-align: left;

  border-radius: 12px;

  &.white {
    color: $black;
    border: 1px solid $grey-5;
    background-color: $white;
  }

  &.blue {
    color: $white;
    border: 1px solid $blue-1;
    background-color: $blue-1;
  }

  &.orange {
    color: $white;
    border: 1px solid $orange-1;
    background-color: $orange-1;
  }

  @include desktop {
    width: 360px;
  }
}

.personImg,
.channelImg {
  border-radius: 50%;
}

.header {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &.media {
    .author {
      display: flex;
      align-items: center;

      height: 48px;
      margin-left: 7px;

      opacity: 1;
    }
  }
}

.author {
  @include text-body;
  margin-left: 15px;
}

.content {
  @include text-body-m;

  margin-top: 11px;

  @include text-with-ellipsis(13, 13, 9);
}

.quote {
  display: flex;
  flex-direction: column;

  margin-top: 25px;

  font-size: 26px;
  font-weight: bold;
  line-height: 34px;

  @include desktop {
    margin-top: 58px;

    @include text-h3;
  }
}

:global(.de) {
  .quote {
    font-size: 24px;

    @include desktop {
      font-size: 30px;
    }
  }
}

.quoteIconWrapper {
  @include desktop {
    margin-left: 2px;
  }
}

.quoteText {
  margin-top: 6px;

  @include text-with-ellipsis(7, 7, 5);

  @include desktop {
    margin-top: 8px;
  }
}

.videoContent {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  height: 100%;
}

.videoAnnotation {
  @include text-body-m;
  margin-top: 18px;
  margin-bottom: 30px;

  @include desktop {
    margin-bottom: 10px;
  }
}

.videoPreviewWrapper {
  overflow: hidden;

  border-radius: 8px;
}

.sourceRow {
  @include text-body;
  display: flex;
  align-items: center;

  margin-top: 13px;

  color: $grey-3;
}

.source {
  display: flex;

  color: $black;
}

.button {
  padding: 0;

  cursor: pointer;

  border: 0;
  background: transparent;

  font: inherit;
}

.logo {
  display: flex;
  align-items: center;

  margin: 0 4px 0 10px;
}

.sourceLinkText {
  margin-left: 6px;
}

.sourceName {
  margin-left: 8px;
}
