@import '../../styles/constants.module';
@import '../../styles/typography';
@import '../../styles/mixins.module';

$image-dir: '/images/home/home-features';

:export {
  IMAGE_DIR: $image-dir;
}

.root {
  position: relative;

  overflow: hidden;

  padding-top: 72px;

  background-image: url('#{$image-dir}/bg_home-features_mobile.svg');
  background-repeat: no-repeat;
  background-position: center 23px;

  @include tablet {
    padding-top: 40px;

    @include small-doodle-bg('14', calc(50% - 299px), 68px);

    .doodleDog {
      top: 184px;
      right: calc(50% + 224px);

      display: block;
    }
  }

  @include desktop {
    padding-top: 49px;

    background-image: url('#{$image-dir}/bg_home-features_desktop.svg');
    background-position: calc(50% - 110px) 52px;

    .doodleDog {
      top: 173px;
      right: calc(50% - 615px);
    }

    .doodleEthereum {
      top: 100px;
      right: calc(50% + 450px);

      display: block;
    }
  }
}

.trustBox {
  height: 108px;

  @include tablet {
    height: 32px;
  }
}

.title {
  margin-top: 52px;
  margin-bottom: 20px;

  text-align: center;

  @include text-h2;

  @include tablet {
    max-width: 440px;
    margin-top: 50px;
  }

  @include desktop {
    max-width: 820px;
    margin-top: 101px;
    margin-bottom: 40px;
  }
}

.description {
  margin-bottom: 50px;

  text-align: center;

  @include text-body-m;

  @include tablet {
    max-width: 440px;
  }

  @include desktop {
    max-width: 820px;
    margin-bottom: 60px;
  }
}

.tabsWrapper {
  // needed in order to avoid mui Tabs warning
  // "Make sure the tab item is present in the document or that it's not `display: none`"
  visibility: hidden;

  overflow: hidden;

  width: 0;
  height: 0;

  background: $blue-4;

  @include desktop {
    display: flex;
    visibility: visible;

    width: $desktop-content-max-width;
    height: 600px;

    padding: 45px 40px 40px 85px;

    border-radius: 12px;
  }
}

.card {
  width: 375px;
  height: 785px;
}

.tabs {
  width: 236px;

  border-color: $black;
}

.tab {
  align-items: end !important;

  width: auto !important;
  height: 100px !important;
  margin-left: auto !important;
  margin-right: 9px !important;
  margin-bottom: 50px !important;

  padding: 8px 16px !important;

  text-align: right !important;
  text-transform: none !important;

  color: $black !important;

  &:last-child {
    margin-bottom: 0;
  }

  &:hover {
    color: $blue-1;
  }

  &:active {
    color: $blue-3;
    box-shadow: none;
  }

  &.selected{
    color: $blue-1 !important;
  }
}

.accordionSummary {
  @include tablet {
    padding: 0 50px;
  }
}

.accordionSummaryContent {
  margin: 0;
  padding: 32px 0;

  @include mui-default-transition((color, padding-bottom));

  color: $white;

  @include text-h3;

  @include tablet {
    padding: 44px 0;
  }
}

.expandIconWrapper {
  position: relative;

  margin-left: 16px;
}

.expandIcon {
  fill: $white;
}

.tabPanel {
  position: relative;

  flex-grow: 1;
}

.tabLabel {
  @include text-h3;
}

.mainText {
  margin-bottom: 10px;

  @include text-h4;

  @include tablet {
    max-width: 440px;
    margin-top: 6px;
    margin-bottom: 20px;
  }

  @include desktop {
    max-width: 640px;
    margin-top: 0;
    margin-bottom: 19px;

    @include text-body-l;
  }
}

.text {
  @include text-body-m;

  @include tablet {
    max-width: 440px;
  }

  @include desktop {
    max-width: 640px;
    margin-bottom: 10px;

    @include text-body-s;
  }
}

.accordionRoot {
  margin-bottom: 1px;

  @include mui-default-transition(background);

  background: $blue-1;
  box-shadow: none;

  &:last-child {
    margin-bottom: 0;
  }

  &::before {
    content: none;
  }

  &.expanded {
    padding-top: 8px;

    background: $blue-4;

    & .accordionSummaryContent {
      padding: 24px 0;

      color: $blue-1;
    }

    @include tablet {
      padding-top: 0;

      & .accordionSummaryContent {
        padding: 44px 0;
      }
    }
  }
}

.accordionDetails {
  padding: 0;
}

.featureDetails {
  position: relative;

  overflow: hidden;

  padding-bottom: 461px;

  @include tablet {
    padding-bottom: 455px;
  }

  @include desktop {
    overflow: visible;

    min-height: 515px;
    padding: 11px 0 0 79px;
  }

  &.tradingTerminal {
    padding-bottom: 131px;

    @include tablet {
      padding-bottom: 127px;
    }

    @include desktop {
      position: static;

      padding: 11px 0 0 79px;
    }
  }
}

.moreButton {
  position: absolute;
  bottom: 32px;
  left: 16px;

  @include tablet {
    bottom: 43px;
    left: 50px;
  }

  @include desktop {
    right: 0;
    bottom: 0;
    left: auto;
  }
}

.arrowIcon {
  width: 18px;
  height: 18px;
  margin-left: 4px;
  margin-top: 2px;
}

.buttonText {
  padding: 0 16px;
}

.imageWrapper {
  position: relative;

  margin-top: 60px;

  &.terminal {
    left: -23px;

    margin-top: 39px;
  }

  @include tablet {
    margin-top: 50px;

    &.terminal {
      left: -21px;

      margin-top: 30px;
    }
  }

  @include desktop {
    margin-top: 0;

    &.terminal {
      position: absolute;
      bottom: -70px;
      left: 56px;
    }
  }
}

.imageCard {
  position: absolute;

  display: flex;

  box-shadow: 0 3px 23px $grey-6;

  &:first-child {
    top: 0;
    left: 16px;
  }

  &:nth-child(2) {
    top: 134px;
    left: -20px;
  }

  &:nth-child(3) {
    top: 219px;
    left: 141px;
  }

  @include tablet {
    &:first-child {
      left: 303px;
    }

    &:nth-child(2) {
      top: 126px;
      left: 0;
    }

    &:nth-child(3) {
      top: 211px;
      left: 303px;
    }
  }

  @include desktop {
    &:first-child {
      right: 45px;
      left: auto;
    }

    &:nth-child(2) {
      top: 77px;
      left: 0;
    }

    &:nth-child(3) {
      top: 162px;
      left: 187px;
    }
  }
}

.themeImage {
  @include desktop {
    margin-top: 30px;
  }
}

.themeSwitcher {
  position: absolute !important;
  right: 16px;
  bottom: 34px;

  display: flex;

  padding: 0;

  border: none;

  border-radius: 0;

  @include tablet {
    right: 50px;
    bottom: 46px;
  }

  @include desktop {
    right: 69px;
    bottom: 332px;
  }
}

.doodleDog {
  position: absolute;
  z-index: -1;

  display: none;

  transform: rotate(-19deg);
}

.doodleEthereum {
  position: absolute;
  z-index: -1;

  display: none;

  transform: rotate(10deg);
}

:global(.id) {
  @include only-mobile {
    .themeSwitcher {
      bottom: 100px;
    }
  }
}
