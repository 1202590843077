@font-face {
  font-family: Inter;
  font-weight: 400;
  font-style: normal;
  font-display: swap;
  src: local('Inter Regular'), local('Inter-Regular'),
    url('./fonts/Inter/inter-v8-latin_cyrillic-regular.woff2') format('woff2');
}

@font-face {
  font-family: Inter;
  font-weight: 700;
  font-style: normal;
  font-display: swap;
  src: local('Inter Bold'), local('Inter-Bold'),
    url('./fonts/Inter/inter-v8-latin_cyrillic-700.woff2') format('woff2');
}
