@import '../../styles/typography';
@import '../../styles/constants.module';
@import '../../styles/mixins.module';

$image-dir: '/images/home/home-reviews';

.root {
  padding: 40px 0 36px;

  background-color: $grey-1;

  @include tablet {
    padding: 50px 0 30px;
  }

  @include desktop {
    position: relative;

    padding: 60px 0 40px;

    &::after {
      content: '';

      position: absolute;
      top: -30px;
      left: calc(50% - 607px);
      z-index: 1;

      display: block;

      width: 1019px;
      height: 230px;

      pointer-events: none;

      background-image: url('#{$image-dir}/bg_home-reviews_desktop.svg');
      background-repeat: no-repeat;
    }

    .doodleRocket {
      top: 60px;
      left: calc(50% + 272px);

      display: block;
    }
  }
}

.title {
  @include text-h2;
  padding: 0 16px;

  @include tablet {
    padding: 0 50px;
  }

  @include only-tablet {
    position: relative;

    width: max-content;

    &::after {
      content: '';

      position: absolute;
      top: -2px;
      right: -97px;

      display: block;

      width: 70px;
      height: 70px;

      @include small-doodle-bg('09');
    }
  }

  @include desktop {
    text-align: center;
  }
}

.filter {
  padding: 15px 16px 20px;

  @include tablet {
    padding: 15px 50px 20px;
  }

  @include desktop {
    width: fit-content;
    margin: auto;
    padding: 34px 0 57px;
  }
}

.doodleRocket {
  position: absolute;

  display: none;
}
