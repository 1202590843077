@import './font-face';
@import './constants.module';
@import './mixins.module';
@import './devices';

html,
body {
  min-width: 360px;
  height: 100%;
  margin: 0;
  padding: 0;

  font-family: $app-font-family;
}

* {
  box-sizing: border-box;
}

a {
  cursor: pointer;

  text-decoration: none;

  color: inherit;

  &:focus {
    outline: none;
  }
}

p {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

main {
  display: flex;
  flex-direction: column;
  flex: 1;
}

@media (prefers-reduced-motion: reduce) {
  path,
  g {
    transform: none;
  }
}

