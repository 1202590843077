@import '../../../styles/mixins.module';

.root {
  height: auto;

  &.large {
    width: 87px;

    @include tablet {
      width: 69px;
    }

    @include desktop {
      width: 87px;
    }
  }

  &.small {
    width: 43px;

    @include tablet {
      width: 53px;
    }

    @include desktop {
      width: 76px;
    }
  }
}
