@import '../../styles/typography';
@import '../../styles/constants.module';

.button {
  @include text-body-m;
  padding: 6px 4px 5px;

  letter-spacing: 0.8px;

  border-bottom: 3px solid transparent;

  &:not(:last-child) {
    margin-right: 21px;

    @include desktop {
      margin-right: 9px;
    }
  }

  &.active {
    border-color: $blue-1;
  }

  @include desktop {
    padding: 7px 8px;
  }
}
