@import '../../styles/constants.module';
@import '../../styles/mixins.module';

$image-dir: '/images/statistics';

.root {
  position: relative;

  &::after {
    content: '';

    position: absolute;

    top: 0;

    width: 100%;
    height: 100%;

    pointer-events: none;

    background-image: url('#{$image-dir}/bg_statistics_home_mobile.svg');
    background-repeat: no-repeat;
    background-position: calc(50% + 8px) 73px;
  }

  @include only-tablet {
    &::after {
      top: -11px;

      background-image: url('#{$image-dir}/bg_statistics_home_tablet.svg');
      background-position: calc(50% + 4px) -10px;
    }
  }

  @include desktop {
    &::after {
      top: -70px;

      background-image: url('#{$image-dir}/bg_statistics_home_desktop.svg');
      background-position: calc(50% - 8px) 0;
    }

    .doodleMagnet {
      top: -70px;
      left: calc(50% - 128px);

      display: block;
    }
  }
}

.list {
  display: grid;

  padding: 50px 0 48px;
  grid-row-gap: 34px;
  grid-template-rows: 1fr 1fr 1fr;

  @include tablet {
    padding: 70px 0;
    grid-row-gap: 36px;

    &.horizontal {
      grid-column-gap: 16px;

      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: unset;
    }
  }

  @include desktop {
    padding: 140px 0;

    &.horizontal {
      grid-column-gap: 21px;
    }

    &.vertical {
      justify-content: start;

      padding: 156px 0;
      grid-row-gap: 64px;

      .label {
        margin-top: 0;

        text-align: left;
      }

      .block {
        @include desktop {
          grid-column: 1;

          &:nth-child(1) {
            grid-row: 2;
          }

          &:nth-child(2) {
            grid-row: 3;
          }

          &:nth-child(3) {
            grid-row: 1;
          }
        }
      }

      .count {
        text-align: left;
      }
    }
  }
}

.block {
  @include desktop {
    width: 400px;
    grid-row: 1;

    &:nth-child(1) {
      grid-column: 2;
    }

    &:nth-child(2) {
      grid-column: 3;
    }

    &:nth-child(3) {
      grid-column: 1;
    }
  }
}

.count {
  text-align: center;

  font-size: 52px;
  font-weight: bold;
  line-height: 63px;

  @include desktop {
    font-size: 72px;
    line-height: 87px;
  }
}

.unit {
  color: $blue-1;
}

.label {
  margin-top: 10px;

  text-align: center;
  text-transform: uppercase;

  font-size: 16px;
  font-weight: bold;
  line-height: 19px;

  @include desktop {
    margin-top: 19px;

    font-size: 20px;
    line-height: 24px;
  }
}

.doodleMagnet {
  position: absolute;

  display: none;
}
