@import '../../../styles/mixins.module';

.root {
  display: flex;

  width: 82px;
  height: 23px;

  margin-right: auto;

  @include keyboard-focus-only;

  @include desktop {
    width: 110px;
    height: 31px;
  }
}
