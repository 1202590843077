@import '../../styles/constants.module';
@import '../../styles/typography';

.root {
  position: relative;

  display: inline-block;

  color: $white;

  margin-left: 15px;

  &::before {
    content: '';

    position: absolute;
    top: 0;
    z-index: -1;

    background-image: url('/images/marker/start-marker.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }

  &::after {
    content: '';

    position: absolute;
    top: 0;
    z-index: -1;

    background-image: url('/images/marker/end-marker.png');
    background-repeat: no-repeat;
    background-size: auto 100%;
  }
}

.bg {
  content: '';

  position: absolute;
  top: 0;
  z-index: -1;

  background-image: url('/images/marker/middle-marker.png');
  background-repeat: repeat-x;
  background-size: 100% 100%;
}

.h1 {
  &::before {
    left: -9px;

    width: 16px;
    height: 46px;

    @include tablet {
      left: -12px;

      width: 15px;
      height: 51px;
    }

    @include desktop {
      left: -18px;

      width: 24px;
      height: 76px;
    }
  }

  &::after {
    right: -13px;

    width: 11px;
    height: 46px;

    @include tablet {
      width: 10px;
      height: 51px;
    }

    @include desktop {
      right: -19px;

      width: 16px;
      height: 76px;
    }
  }

  .bg {
    left: 4px;

    width: 100%;
    height: 46px;

    @include tablet {
      left: 2px;

      width: calc(100% + 2px);
      height: 51px;
    }

    @include desktop {
      left: 4px;

      width: calc(100% + 4px);
      height: 76px;
    }
  }
}

.h1 + .h1 {
  &::before {
    left: -18px;

    @include desktop {
      left: -26px;
    }
  }

  &::after {
    right: -9px;

    @include desktop {
      right: -15px;
    }
  }

  .bg {
    left: -5px;

    width: calc(100% + 5px);
  }
}

.h2 {
  &::before {
    left: -10px;

    width: 16px;
    height: 40px;

    @include desktop {
      top: -4px;
      left: -19px;

      width: 20px;
      height: 70px;
    }
  }

  &::after {
    right: -10px;

    width: 11px;
    height: 40px;

    @include tablet {
      right: -12px;
    }

    @include desktop {
      top: -4px;
      right: -15px;

      width: 12px;
      height: 70px;
    }
  }

  .bg {
    left: 0;

    width: 100%;
    height: 40px;

    @include tablet {
      width: calc(100% + 2px);
    }

    @include desktop {
      top: -4px;

      width: calc(100% + 4px);
      height: 70px;
    }
  }
}

.h2 + .h2 {
  &::before {
    @include desktop {
      left: -23px;
    }
  }

  &::after {
    @include desktop {
      right: -10px;
    }
  }

  .bg {
    @include desktop {
      left: -4px;

      width: calc(100% + 4px);
    }
  }
}

.h3 {
  &::before {
    left: -10px;

    width: 16px;
    height: 36px;

    @include tablet {
      height: 40px;
    }

    @include desktop {
      top: -2px;
      left: -10px;

      width: 20px;
      height: 44px;
    }
  }

  &::after {
    right: -10px;

    width: 11px;
    height: 36px;

    @include tablet {
      right: -14px;

      height: 40px;
    }

    @include desktop {
      top: -2px;
      right: -18px;

      width: 12px;
      height: 44px;
    }
  }

  .bg {
    left: 0;

    width: 100%;
    height: 36px;

    @include tablet {
      width: calc(100% + 4px);
      height: 40px;
    }

    @include desktop {
      top: -2px;

      width: calc(100% + 8px);
      height: 44px;
    }
  }
}
