@import '../../styles/constants.module';
@import '../../styles/mixins.module';

.root {
  &:active {
    .background {
      opacity: 0.3;
    }

    .border {
      display: none;
    }

    .moonBackground {
      fill: $grey-11;
    }
  }

  @include desktop {
    &:hover {
      .border {
        display: block;
        stroke: $grey-3;
      }

      .moonBackground {
        fill: $grey-3;
      }
    }
  }
}

:global(.Mui-focusVisible) {
  .border {
    display: block;
    stroke: $blue-1;
  }
}

.border {
  display: none;
}

.moonBackground {
  fill: $grey-11;
}

.sun {
  fill: $grey-3;
}

.sunray {
  stroke: $grey-3;
}

.light {
  .moon {
    fill: $grey-3;
  }

  @include desktop {
    &:hover {
      .moon {
        fill: $black;
      }
    }
  }
}

.dark {
  .moon {
    fill: $white;
  }

  &:active {
    .sun {
      fill: $grey-3;
    }

    .sunray {
      stroke: $grey-3;
    }
  }

  @include desktop {
    &:hover {
      .sun {
        fill: $black;
      }

      .sunray {
        stroke: $black;
      }
    }
  }
}
