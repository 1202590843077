@import '../../styles/constants.module';
@import '../../styles/mixins.module';

.root {
  padding: 6.5px 16px !important;

  border-radius: 5px !important;

  line-height: 22px !important;

  @include keyboard-focus-only;

  &:hover,
  &.hovered {
    background-color: $grey-1;
  }
}
