@import '../../styles/mixins.module';

.root {
  display: grid;

  padding: 28px 23px 40px;
  grid-column-gap: 12px;
  grid-template-columns: auto auto;

  &.authorized {
    grid-template-columns: none;
  }

  @include tablet {
    padding: 28px 50px 50px;
    grid-column-gap: 6px;
  }

  @include desktop {
    display: flex;

    padding: 0;
    grid-column-gap: 9px;
  }
}

.loginButton {
  height: 35px;
  margin: auto 0;
}

.buttonText {
  white-space: nowrap;
}

.ru,
.fr {
  display: block;

  button:first-child {
    margin-bottom: 16px;
  }
}
