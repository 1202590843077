@import '../../styles/typography';
@import '../../styles/constants.module';
@import '../../styles/mixins.module';

.root {
  padding: 30px 16px 76px;

  @include tablet {
    padding: 30px 0 0;
  }

  @include desktop {
    padding-top: 36px;
  }

  &.white {
    background-color: $white;
  }

  &.grey {
    background-color: $grey-1;
  }
}

.mainContent {
  padding-bottom: 30px;

  @include desktop {
    display: flex;
  }
}

.copyright {
  max-width: 265px;

  @include text-body;

  @include tablet {
    max-width: 460px;
  }

  @include desktop {
    width: 305px;
    max-width: none;
  }
}

.socialMedia {
  display: flex;
  flex-wrap: wrap;

  margin: 16px 0 15px;

  @include text-body;

  > :not(:last-child) {
    margin-right: 37px;

    @include desktop {
      margin-right: 12px;
    }
  }

  > span {
    margin-bottom: 16px;
  }

  @include tablet {
    margin-bottom: 14px;
  }

  @include desktop {
    margin-top: 25px;
    margin-bottom: 0;
  }
}

.accordionRoot {
  padding: 5px 0;

  border-top: 1px solid $grey-2;
  background-color: unset;
  box-shadow: none;

  &::before {
    display: none;
  }
}

.accordionSummary {
  min-height: 70px;
  padding: 0;

  font-weight: bold;

  @include text-body-m;
}

.accordionSummaryContent {
  margin: 0 20px 0 0;
}

.expandIconWrapper {
  position: relative;
  top: 23px;

  align-self: flex-start;
}

.expandIcon {
  width: 22px;
  height: 22px;
}

.accordionDetails {
  padding: 2px 0 24px;
}

.navLinks {
  display: flex;
  flex-direction: column;

  @include text-body;

  > :not(:last-child) {
    margin-bottom: 27px;
  }
}

.navLink {
  display: flex;
}

.withIndent {
  margin-left: 14px;
}

.navigation {
  display: grid;
  grid-column-gap: 19px;
  grid-template-columns: 1fr 1fr 1fr 1fr;

  @include only-tablet {
    padding-top: 30px;
    padding-bottom: 17px;

    border-top: 1px solid $grey-2;
  }

  @include desktop {
    width: 100%;
    margin-left: 116px;
    padding-bottom: 20px;
    grid-column-gap: 70px;
  }
}

.navList,
.navListLinks {
  display: flex;
  flex-direction: column;

  > :not(:last-child) {
    margin-bottom: 8px;
  }
}

.navListItem {
  font-size: 16px;
  line-height: 24px;

  &.bold {
    font-weight: bold;
  }
}

.quickLinksWrapper {
  padding-bottom: 8px;

  &.white {
    background-color: $white;
  }

  &.grey {
    background-color: $grey-13;
  }

  &.withDivider {
    .supportedCoins,
    .supportedExchanges {
      border-color: $grey-2;
    }
  }
}

.supportedCoins {
  @include tablet {
    padding: 30px 0 3px;
    border-top: 1px solid transparent;
  }

  @include desktop {
    padding: 8px 0;
  }
}

.hidden {
  display: none;
}

.supportedExchanges {
  @include tablet {
    padding: 30px 0 11px;

    border-top: 1px solid transparent;
  }

  @include desktop {
    padding: 19px 0 8px;
  }
}

.quickLinks {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > :not(:last-child) {
    margin-right: 8px;
  }

  > * {
    margin-bottom: 19px;

    @include desktop {
      margin-bottom: 4px;
    }
  }
}

.quickLink {
  padding: 0 8px;

  color: rgba(0, 0, 0, 0.77);
  border-radius: 4px;

  font-size: 16px;
  line-height: 24px;

  @include keyboard-focus-only;

  &:hover {
    color: $blue-1;
  }

  &.grey {
    background-color: $grey-1;

    &:active {
      color: $blue-3;
      background-color: #ededed;
    }
  }

  &.white {
    background-color: $white;
  }
}

.transparentButton {
  margin: 0;
  padding: 0 !important;

  text-align: left;

  color: $black !important;
  border: 0 !important;
  background: none;

  font-family: inherit;

  font-size: 14px !important;

  @include tablet {
    font-size: 16px !important;
  }

  @include keyboard-focus-only;

  &:hover {
    cursor: pointer;

    color: $blue-1 !important;
    background: none !important;
  }
}
