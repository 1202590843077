@import '../../../styles/mixins.module';

.root {
  height: auto;

  &.large {
    width: 113px;

    @include tablet {
      width: 107px;
    }

    @include desktop {
      width: 153px;
    }
  }

  &.small {
    width: 62px;

    @include tablet {
      width: 73px;
    }

    @include desktop {
      width: 100px;
    }
  }
}
